import React from 'react';

const MortgageEarlyFAQ = () => ([
    {
      title: 'Can you pay off your mortgage earlier?',
      description: (
        <>
          Most mortgages allow you to pay off your mortgage earlier than the terms set out in your mortgage deal. <br />
          However, there are usually early repayment charges that are associated. These fees charged by the lender are usually between 1% and 5% of the mortgage debt remaining.<br />
          Your early repayment charge can be paid in a lump sum, which may be wise if you're planning on paying off the remainder of the mortgage.
        </>
      ),
    },
    {
      title: 'What are mortgage overpayments?',
      description: (
        <>
          A mortgage overpayment is an additional payment towards your mortgage on top of your monthly payments.<br/><br/>
          These can be made with spare cash either as a lump sum or as regular smaller overpayments. <br />
          Mortgage lenders typically allow overpaying around 10% of the annual mortgage balance before they charge an early repayment charge.
        </>
      ),
    },
    {
      title: 'Benefits of paying off your mortgage early',
      description: (
        <>
          The key benefit of paying off your mortgage early and living mortgage-free is the financial freedom that comes with it.<br/><br/>
          As a mortgage is likely to be your largest financial commitment and debt, not having to worry about this offers greater freedom over your financial situation, pays less interest overall and will also result in less stress and anxiety that comes along with debts.<br />
          Reduces the overall interest that you'll pay to your mortgage company. Reducing the mortgage term by a few years could save you thousands in mortgage interest payments.<br />
          Once you've paid off your mortgage, your home will be owned by you fully which means any extra cash can now go towards other debts, a pension scheme, saving accounts or whoever you choose.
        </>
      ),
    },
    {
      title: 'Does paying off your mortgage early affect mortgage interest?',
      description: (
        <>
          The sooner you pay off your mortgage, the less mortgage interest you'll pay to your lender overall.<br/><br/>
          By making mortgage overpayments, you'll be chipping away at the interest owed on your mortgage and thus reducing your mortgage term therefore saving in interest.
        </>
      ),
    },
    {
      title: 'Are there any drawbacks to paying off your mortgage early?',
      description: (
        <>
          Although paying off your mortgage early can offer great benefits, it's important to ensure this is the right option for you.<br/><br/>
          There are some things to take into consideration when making overpayments. Some mortgages might have early repayment charges or restrictions on overpayments, so it's important to check your mortgage terms before making extra payments.<br />
          It can be difficult to get the money back once you've made the payment to your mortgage lender.<br />
          It's wise to have extra money or an emergency fund in a savings account set aside in case of any unforeseen circumstances so you have access to this before making overpayments on your mortgage either as a lump sum or as regular small payments.<br />
          If you have other debts, it's worth researching and seeing if it's a better idea to pay down those other debts that are on a higher interest rate. Whilst mortgage interest rates can be high, there are other loans that tend to come in at a higher rate and will have more expensive costs, such as credit cards. <br />
          1. Download Sprive Today Sprive supports 13 of the largest lenders in the UK. It’s completely free.
        </>
      ),
    },
  ]);

export default MortgageEarlyFAQ;
